import {getFunctions, httpsCallable, HttpsCallableResult} from "firebase/functions";
import {Subscription} from "../../models/Subscription";

// TODO retourner une promise plutot
export const createSignatureRequestCF = (subscription: Subscription, setIframeUrl: (link: string) => void) => {
    const createSignature = httpsCallable(getFunctions(), 'V3_youSignCreateSignature');
    createSignature(subscription).then((result: HttpsCallableResult<any>) => {
        setIframeUrl(result.data.signature_link)
    }).catch((error) => {
        console.log(error)
    });
}