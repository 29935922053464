import React, {FormEventHandler} from "react";

interface Prop {
  action: string | undefined,
  submitFunction: FormEventHandler,
  className: string | undefined,
  children: any,
}

function GenericForm({action, submitFunction, className, children}: Prop) {
  return (
      <form action={action} className={className} onSubmit={e => submitFunction(e)}>
        {children}
      </form>
  )
}

export default GenericForm
