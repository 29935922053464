import React, {FormEvent, useEffect, useState} from "react";
import {searchUserInfoCF} from "../cloudFunctions/admin/searchUserInfoCF";
import {Claims} from "../models/Claims";
import {setClaimsCF} from "../cloudFunctions/admin/setClaimsCF";
import {updateUserInfoCF} from "../cloudFunctions/admin/updateUserInfoCF";
import {AdminUpdateUserInfoDto} from "../models/AdminUpdateUserInfoDto";
import {Box, Button, Checkbox, Container, FormControlLabel, Grid, TextField, Typography} from "@mui/material";

export const AdminPagePath = "/admin"


function AdminPage() {
  const [searchBarInput, setSearchBarInput] = useState("");
  const [rawData, setRawData] = useState<any>("");
  const [claims, setClaims] = useState({
    admin: false,
    seller: "",
    manager: "",
    stripeRole: ""
  });

  const [userInfo, setUserInfo] = useState({
    email: "",
    phoneNumber: "",
    displayName: "",
    photoURL: ""
  });

  useEffect(() => {

  }, [rawData]);


  // SEARCH BAR
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBarInput(event.currentTarget.value);
  };
  // Fonction appelée lors de la soumission du formulaire
  const handleSearch = (event: FormEvent) => {
    event.preventDefault(); // Empêche le rechargement de la page
    searchUserInfoCF(searchBarInput).then((res) => {
      setRawData(res)

      const newClaims = {
        admin: res?.customClaims?.admin ? res.customClaims?.admin : false,
        seller: res?.customClaims?.seller ? res.customClaims?.manager?.toString() : "",
        manager: res?.customClaims?.manager ? res.customClaims?.manager?.toString() : "",
        stripeRole: res?.customClaims?.stripeRole ? res.customClaims?.stripeRole : ""
      }

      setClaims(newClaims)


      // Ne pas mettre de champs vide si les champs sont null
      const newUserInfo = {
        email: res.email ? res.email : "",
        phoneNumber: res.phoneNumber ? res.phoneNumber : "",
        displayName: res.displayName ? res.displayName : "",
        photoURL: res.phoneURL ? res.phoneURL : ""
      }

      setUserInfo(newUserInfo)

    })
  };


  // CLAIMS
  const handleAdminChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newClaims = {...claims};
    newClaims.admin = !newClaims.admin;
    setClaims(newClaims);
  };

  const stripeRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newClaims = {...claims};
    newClaims.stripeRole = event.currentTarget.value;
    setClaims(newClaims);
  };

  const sellerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newClaims = {...claims};
    newClaims.seller = event.currentTarget.value;
    setClaims(newClaims);
  };

  const managerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newClaims = {...claims};
    newClaims.manager = event.currentTarget.value;
    setClaims(newClaims);
  };

  const handleSubmitClaims = (event: FormEvent) => {
    event.preventDefault(); // Empêche le rechargement de la page

    const newClaims: Claims = {
      admin: claims.admin,
      manager: claims.manager.replace(/\s/g, '').split(","),
      seller: claims.seller.replace(/\s/g, '').split(","),
      stripeRole: claims.stripeRole
    }

    if(claims.manager.trim() === ""){
      newClaims.manager = undefined
    }

    if(claims.seller.trim() === ""){
      newClaims.seller = undefined
    }

    setClaimsCF({
      uid: rawData.uid,
      claims: newClaims
    }).then((res) => {
      console.log("Claims setted")
    })
  };


  // USER INFOS
  const emailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUserInfo = {...userInfo};
    newUserInfo.email = event.currentTarget.value;
    setUserInfo(newUserInfo);
  };

  const photoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUserInfo = {...userInfo};
    newUserInfo.photoURL = event.currentTarget.value;
    setUserInfo(newUserInfo);
  };

  const phoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUserInfo = {...userInfo};
    newUserInfo.phoneNumber = event.currentTarget.value;
    setUserInfo(newUserInfo);
  };

  const displayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUserInfo = {...userInfo};
    newUserInfo.displayName = event.currentTarget.value;
    setUserInfo(newUserInfo);
  };

  const handleSubmitUserInfo = (event: FormEvent) => {
    event.preventDefault(); // Empêche le rechargement de la page

    const newUserInfo: AdminUpdateUserInfoDto = {
      uid: rawData.uid,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      displayName: userInfo.displayName,
      photoURL: userInfo.photoURL,
    }

    if(userInfo.email.trim().length === 0){
      newUserInfo.email = undefined
    }
    if(userInfo.phoneNumber.trim().length === 0){
      newUserInfo.phoneNumber = undefined
    }
    if(userInfo.displayName.trim().length === 0){
      newUserInfo.displayName = undefined
    }
    if(userInfo.photoURL.trim().length === 0){
      newUserInfo.photoURL = undefined
    }

    updateUserInfoCF(newUserInfo).then((res) => {
      console.log("Claims setted")
    })
  };

  // FIXME ajouter les id des champs / formulaire
  return (
      <Container>
        <Grid container spacing={2}>

          {/*TITRE PRINCIPAL*/}
          <Grid item xs={12}>
            <Typography variant="h1">Admin</Typography>
          </Grid>

          {/*COLONNE 1*/}
          <Grid item spacing={2} xs={12} md={6}>
            <Box>
              <Grid container spacing={2} item xs={12}>

                <Grid item xs={12}>
                  <Typography variant="h3">Recherche</Typography>
                </Grid>


                <Grid item xs={12}>
                  <form onSubmit={handleSearch}>

                    <Grid container spacing={2} xs={12} alignItems="center">

                      <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            label="Email / Téléphone"
                            value={searchBarInput}
                            onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Button fullWidth variant="contained" type="submit" size="large">Soumettre</Button>
                      </Grid>
                    </Grid>

                  </form>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h3">User Record</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1">
                    <pre style={{ whiteSpace: "pre-wrap"}}>
                      {JSON.stringify(rawData, null, 2)}
                    </pre>
                  </Typography>
                </Grid>
              </Grid>

            </Box>
          </Grid>

          {/*COLONNE 2*/}
          <Grid container item spacing={2} xs={12} md={6}>
            <Box>
              <Grid container spacing={2} item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="h3">Claims</Typography>
                </Grid>

                <Grid item xs={12}>
                  <form onSubmit={handleSubmitClaims}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            value="start"
                            control={
                              <Checkbox name="checkboxName" checked={claims.admin ? claims.admin : false}
                                        onChange={handleAdminChange}/>
                            }
                            label="Admin"
                            labelPlacement="start"
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Seller"
                            value={claims.seller ? claims.seller : ""}
                            onChange={sellerChange}/>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Manager"
                            value={claims.manager ? claims.manager : ""}
                            onChange={managerChange}/>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="StripeRole"
                            value={claims.stripeRole ? claims.stripeRole : ""}
                            onChange={stripeRoleChange}/>
                      </Grid>

                      <Grid item xs={12}>
                        <Button fullWidth variant="contained" type="submit">Soumettre</Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>


                <Grid item xs={12}>
                  <Typography variant="h3">User infos</Typography>
                </Grid>

                <Grid item xs={12}>
                  <form onSubmit={handleSubmitUserInfo}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Display Name"
                            value={userInfo.displayName ? userInfo.displayName : ""}
                            onChange={displayNameChange}/>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            value={userInfo.email ? userInfo.email : ""}
                            onChange={emailChange}/>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Phone Number"
                            value={userInfo.phoneNumber ? userInfo.phoneNumber : ""}
                            onChange={phoneChange}/>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Photo URL"
                            value={userInfo.photoURL ? userInfo.photoURL : ""}
                            onChange={photoChange}/>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Button fullWidth variant="contained" type="submit">Soumettre</Button>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
  );
}

export default AdminPage;