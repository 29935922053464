import React, {ChangeEvent, useState} from "react";
import arrow from "../../assets/icons/arrow.svg";
import {useSelector} from "react-redux";
import {priceToFriendlyName} from "../../utils/PriceToFriendlyName";
import {Subscription} from "../../models/Subscription";
import {RootState} from "../../redux/store";
import {User} from "../../models/User";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';

interface Prop {
  nextStep: () => void;
  subscription: Subscription;
  setSubscription: React.Dispatch<React.SetStateAction<Subscription>>;
}

/* eslint-disable */
const validNameRegex = /^[A-Za-z\s\-]+$/; // Lettres, espaces et tirets uniquement
const validFrenchPhoneRegex = /^\+33\d{9}$/; // Format +33 suivi de 9 chiffres
const validAddressRegex = /^[A-Za-z0-9\s\-]+$/; // Chiffres, lettres, espaces et tirets
const validPostalCodeRegex = /^\d{5}$/;  // 5 chiffres uniquement
const validCityRegex = /^[a-zA-Z\s\-]+$/; // Uniquement des lettres, espaces et tirets "-"
const validEmailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const validDepartmentRegex = /^[a-zA-Z\s\-]+$/;  //
/* eslint-enable */

function GetUserInfos({nextStep, subscription, setSubscription}: Prop) {


  const user = useSelector((state: RootState) => (state.userState.user as User));
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false)

  const handleSubmit = () => {
    if (!subscription || !subscription.customer) {
      return;
    }
    let valid = true;

    if (!validNameRegex.test(subscription.customer.lastName) || subscription.customer.lastName === "") {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }

    if (!validNameRegex.test(subscription.customer.firstName) || subscription.customer.firstName === "") {
      setFirstNameError(true);
      valid = false;
    } else {
      setFirstNameError(false);
    }

    if (!validAddressRegex?.test(subscription?.customer?.address?.line1) || subscription.customer.address.line1 === "") {
      setAddressError(true);
      valid = false;
    } else {
      setAddressError(false);
    }


    if (!validPostalCodeRegex.test(subscription.customer.address.postalCode) || subscription.customer.address.postalCode === "") {
      setPostalCodeError(true);
      valid = false;
    } else {
      setPostalCodeError(false);
    }

    if (!validCityRegex.test(subscription.customer.address.city) || subscription.customer.address.city === "") {
      setCityError(true);
    } else {
      setCityError(false);
    }

    // if (!validCountryRegex.test(subscription.customer.address.country) || subscription.customer.address.country === "") {
    //   setDepartmentError(true);
    // } else {
    //   setDepartmentError(false);
    // }

    if (!validDepartmentRegex.test(subscription.customer.address.state) || subscription.customer.address.state === "") {
      setCountryError(true);
    } else {
      setCountryError(false);
    }

    if (!validFrenchPhoneRegex.test(subscription.customer.phone) || subscription.customer.phone === "") {
      setMobileError(true);
      valid = false;
    } else {
      setMobileError(false);
    }

    if (!validEmailRegex.test(subscription.customer.email) || subscription.customer.email === "") {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }

    if (valid) {
      console.log("ok")
      nextStep()
    } else {
      setErrorMessage(true);
      console.log('not-ok')
    }
  };

  //--------------------------------------//
  // FONCTION DE GESTION DES INPUT CHANGE //

  function shopChange(event: SelectChangeEvent<string>) {
    event.preventDefault();
    const tempSubscription: Subscription = {...subscription} as Subscription;
    const selectedShopName = user.shops.get(event.target.value);
    if (selectedShopName) {
      tempSubscription.shop.name = selectedShopName;
      tempSubscription.shop.id = event.target.value;
      setSubscription(tempSubscription);
    } else {
      console.error("Shop not found for id:", event.target.value);
    }
  }

  function sellerChange(e: SelectChangeEvent<string>) {
    e.preventDefault()
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.seller.name = user.sellers.get(e.target.value) as string
    tempSubscription.seller.id = e.target.value
    setSubscription(tempSubscription)
  }

  function firstNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const tempSubscription: Subscription = {...subscription};
    tempSubscription.customer.firstName = value.charAt(0).toUpperCase() + value.slice(1);
    setSubscription(tempSubscription);
  }

  function lastNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const tempSubscription: Subscription = {...subscription};
    tempSubscription.customer.lastName = value.toUpperCase();
    setSubscription(tempSubscription);
  }

  function addressChange(e: React.ChangeEvent<HTMLInputElement>) {
    const tempSubscription: Subscription = {...subscription};
    tempSubscription.customer.address.line1 = e.target.value;
    setSubscription(tempSubscription);
  }

  function address2Change(e: React.ChangeEvent<HTMLInputElement>) {
    const tempSubscription: Subscription = {...subscription};
    tempSubscription.customer.address.line2 = e.target.value;
    setSubscription(tempSubscription);
  }

  function postalCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    const tempSubscription: Subscription = {...subscription};
    tempSubscription.customer.address.postalCode = e.target.value;
    setSubscription(tempSubscription);
  }

  function cityChange(e: React.ChangeEvent<HTMLInputElement>) {
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.address.city = e?.currentTarget.value
    setSubscription(tempSubscription)
  }

  function departmentChange(e: React.ChangeEvent<HTMLInputElement>) {
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.address.country = e.currentTarget.value
    setSubscription(tempSubscription)
  }

  function countryChange(e: React.ChangeEvent<HTMLInputElement>) {
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.address.state = e.currentTarget.value
    setSubscription(tempSubscription)
  }

  function mobileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.phone = e.currentTarget.value
    setSubscription(tempSubscription)
  }

  function genderChange(e: React.FormEvent<HTMLInputElement>) {
    e.preventDefault()
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.gender = e.currentTarget.value
    setSubscription(tempSubscription)
  }

  function birthdateChange(e: ChangeEvent<HTMLInputElement>) {
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.birthdate = e.currentTarget.value;
    setSubscription(tempSubscription);
  }

  const emailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.customer.email = e.currentTarget.value;
    setSubscription(tempSubscription);
  };

  function offerChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    const tempSubscription: Subscription = {...subscription}
    tempSubscription.price = e.currentTarget.value
    setSubscription(tempSubscription)
  }

  if (user.sellers.size === 0 || user.shops.size === 0) {
    return <div>Chargement...</div>;
  }


  return (
      <div className="step">
        <h2 className="step-title">
          <span>Étape 1</span>: Renseigner les informations du nouvel utilisateur
        </h2>
        <Grid container spacing={3} className="user-infos_form">
          <Grid item xs={12} md={6}>
            <h3 className="subtitle">Informations sur le vendeur :</h3>
            <FormControl
                required={true}
                fullWidth variant="outlined">
              <InputLabel htmlFor="shop">Quelle Boutique ?</InputLabel>
              <Select
                  labelId="shop-label"
                  id="shop"
                  name="selectedShop"
                  value={subscription.shop.id}
                  onChange={shopChange}
                  label="Quelle Boutique ?"
              >
                {Array.from(user.shops).map(([shopId, shopName]) => (
                    <MenuItem key={shopId} value={shopId}>
                      {shopName}
                    </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
                required={true}
                fullWidth>
              <InputLabel id="sellers-label">Nom du vendeur</InputLabel>
              <Select
                  labelId="sellers-label"
                  id="sellers"
                  name="selectedSeller"
                  onChange={sellerChange}
                  value={subscription.seller.id}
              >
                {Array.from(user.sellers).map(([sellerId, sellerName]) => (
                    <MenuItem key={sellerId} value={sellerId}>{sellerName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <h3 className="subtitle">Informations sur le client :</h3>

            <FormControl
                required={true}
                component="fieldset"
                fullWidth>
              <legend className="civ">Civilité</legend>
              <RadioGroup name="gender" onChange={genderChange}>
                <FormControlLabel value="homme" control={<Radio/>} label="Monsieur"/>
                <FormControlLabel value="Femme" control={<Radio/>} label="Madame"/>
                <FormControlLabel value="neutral" control={<Radio/>} label="Neutre"/>
              </RadioGroup>
            </FormControl>

            <TextField
                label="Nom"
                id="lastName"
                name="lastName"
                required={true}
                value={subscription.customer.lastName}
                onChange={lastNameChange}
                variant="outlined"
                error={lastNameError}
                helperText={lastNameError ? "Le nom doit uniquement contenir des lettres, des espaces et des tirets." : ""}
                fullWidth/>

            <TextField
                label="Prénom"
                id="firstName"
                name="firstName"
                required={true}
                value={subscription.customer.firstName}
                onChange={firstNameChange}
                variant="outlined"
                error={firstNameError}
                helperText={firstNameError ? "Le prénom doit uniquement contenir des lettres, des espaces et des tirets." : ""}
                fullWidth/>

            <TextField
                label="Date de naissance"
                type="date"
                required={true}
                value={subscription.customer.birthdate}
                onChange={birthdateChange}
                InputLabelProps={{shrink: true}}
                fullWidth/>

          </Grid>


          <div className="factu">
            <h3 className="subtitle">Adresse de facturation :</h3>
          </div>

          <Grid item xs={12} md={6}>

            <TextField
                label="Adresse*"
                id="address"
                name="address"
                value={subscription.customer.address.line1}
                onChange={addressChange}
                variant="outlined"
                error={addressError}
                helperText={addressError ? "L'adresse doit uniquement contenir des chiffres, des lettres, des espaces et des tirets." : ""}
                fullWidth/>

            <TextField
                label="Complément d'adresse"
                id="address2"
                name="address2"
                value={subscription.customer.address.line2}
                onChange={address2Change}
                variant="outlined"
                // error={address2Error}
                // helperText={address2Error ? "Le complément d'adresse doit uniquement contenir des chiffres, des lettres, des espaces et des tirets." : ""}
                fullWidth/>

            <TextField
                label="Code postal*"
                id="postalCode"
                name="postalCode"
                value={subscription.customer.address.postalCode}
                onChange={postalCodeChange}
                variant="outlined"
                error={postalCodeError}
                helperText={postalCodeError ? "Le code postal doit uniquement contenir 5 chiffres." : ""}
                fullWidth/>

            <TextField
                label="Ville*"
                id="city"
                name="city"
                value={subscription.customer.address.city}
                onChange={cityChange}
                variant="outlined"
                error={cityError}
                helperText={cityError ? "La ville doit uniquement contenir des lettres, des espaces et des tirets." : ""}
                fullWidth/>
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
                label="Département"
                id="department"
                name="department"
                value={subscription.customer.address.country}
                onChange={departmentChange}
                variant="outlined"
                // error={departmentError}
                // helperText={departmentError ? "Le département doit uniquement contenir des chiffres, des lettres, des espaces et des tirets." : ""}
                fullWidth/>

            <TextField
                label="Pays"
                required={true}
                id="country"
                name="country"
                value={subscription.customer.address.state}
                onChange={countryChange}
                variant="outlined"
                error={countryError}
                helperText={countryError ? "Le pays doit uniquement contenir des lettres, des chiffres, des espaces et des tirets." : ""}
                fullWidth/>

            <TextField
                label="Téléphone mobile"
                id="mobile"
                name="mobile"
                required={true}
                value={subscription.customer.phone}
                onChange={mobileChange}
                variant="outlined"
                error={mobileError}
                helperText={mobileError ? "Le format attendu est +33 suivi de 9 chiffres." : ""}
                fullWidth/>

            <TextField
                label="Email"
                required={true}
                id="email"
                name="email"
                value={subscription.customer.email}
                onChange={emailChange}
                variant="outlined"
                error={emailError}
                helperText={emailError ? "Veuillez entrer un email valide." : ""}
                fullWidth/>
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <h3 className="subtitle">Formule souhaitée :</h3>
              <RadioGroup name="offer" value={subscription.price} onChange={offerChange}>
                <FormControlLabel
                    value={process.env.REACT_APP_STRIPE_SILVER_OFFER}
                    control={<Radio/>}
                    label={priceToFriendlyName(process.env.REACT_APP_STRIPE_SILVER_OFFER as string)}
                />
                <FormControlLabel
                    value={process.env.REACT_APP_STRIPE_GOLD_OFFER}
                    control={<Radio/>}
                    label={priceToFriendlyName(process.env.REACT_APP_STRIPE_GOLD_OFFER as string)}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {errorMessage && (
              <Grid item xs={12}>
                <div className="error-message font-medium">Veuillez remplir tous les champs obligatoires</div>
              </Grid>
          )}

          <Grid item xs={12} container justifyContent="flex-end">
            <Button
                variant="contained"
                onClick={handleSubmit}
                endIcon={<img className="arrow" src={arrow} alt=""/>}>
              Etape suivante
            </Button>
          </Grid>
        </Grid>
      </div>
  );
}

export default GetUserInfos;
