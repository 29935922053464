import {getFunctions, httpsCallable, HttpsCallableResult} from "firebase/functions";

export function searchUserInfoCF(search: string): Promise<any> {
  return new Promise((resolve, reject) => {
    const createSubscription = httpsCallable(getFunctions(), 'V2_getUserByEmailOrPhoneNumber');

    createSubscription(search).then((result: HttpsCallableResult<any>) => {
      console.log("options : ", JSON.parse(result.data));
      resolve(JSON.parse(result.data));
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}