// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";

import {getFirestore} from "firebase/firestore";
import {getAuth, inMemoryPersistence, setPersistence} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string);

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
setPersistence(auth, inMemoryPersistence);
export const firestore = getFirestore(app);