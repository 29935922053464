import React from "react";
import {Route, Routes} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import "./sass/utils/reset.scss"
import 'react-toastify/dist/ReactToastify.css';
import SubscriptionList, {SubscriptionsListPath} from "./pages/subscriptions/SubscriptionList";
import "./sass/App.scss"
import Subscribe, {SubscribePath} from "./pages/Subscribe";
import {useSelector} from "react-redux"
import './sass/App.scss';
import {loadStripe} from "@stripe/stripe-js";
import {RootState} from "./redux/store";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Header from "./components/generic/Header";
import AdminPage, {AdminPagePath} from "./pages/AdminPage";


export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);

function App() {

  const isLogged = useSelector((state: RootState) => state.userState.user)

  return (

      <div className="App">
        {isLogged ? (<Header/>) : null}

        <Routes>
          <Route path="/" element={isLogged ? (<SubscriptionList/>) : <Login/>}/>
          <Route path={SubscribePath} element={isLogged ? (<Subscribe/>) : <Login/>}/>
          <Route path={SubscriptionsListPath} element={isLogged ? (<SubscriptionList/>) : <Login/>}/>
          {/*FIXME n'afficher que si admin*/}
          <Route path={AdminPagePath} element={isLogged ? (<AdminPage/>) : <Login/>}/>
          <Route path="/confirm" element={isLogged ? (<SubscriptionList/>) : <Login/>}/>
          <Route path="*" element={isLogged ? (<NotFound/>) : <Login/>}/>
        </Routes>
        <ToastContainer/>
      </div>
  );
}

export default App;