import React, {useEffect, useState} from "react";
import NestedSubscriptionList from "./NestedSubscriptionList";
import "../../sass/pages/UserList.scss";
import "../../sass/components/userList.scss"
import {getFunctions, httpsCallable, HttpsCallableResult} from "firebase/functions";
import {SubscriptionDto} from "../../models/StripeDto";

export const SubscriptionsListPath = "/subscriptions"

function SubscriptionList() {
  const [displayedSubscriptions, setDisplayedSubscriptions] = useState<SubscriptionDto[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [loadInitialSubscriptions, setLoadInitialSubscriptions] = useState<boolean>(true);

  const subs: SubscriptionDto[] = []


  // Premier lancement
  useEffect(() => {
    if (loadInitialSubscriptions) {
      console.log("Use effect subs");
      loadPage(undefined)
      setLoadInitialSubscriptions(false)
    }
  }, [loadInitialSubscriptions]);


  function loadPage(pageIndex?: string) {
    const loadSubscriptions = httpsCallable(getFunctions(), 'V3_searchStripeSubscriptions');
    loadSubscriptions({
      // query: query,
      page: pageIndex
    }).then((result: HttpsCallableResult<any>) => {

      console.log("Result : ", result.data)

      subs.push(...result.data.data)

      if (result.data.has_more) {
        loadPage(result.data.next_page)
      } else {
        setDisplayedSubscriptions(subs)
      }
      setLoadingData(false);
    }).catch((error) => {
      console.log("Erreur du loadSubscriptions : ", error)
      setLoadingData(false);
    });
  }

  // function generateCSVData(filteredCustomers) {
  //     const headers = ["id BDD", "date de création", "Prénom", "Nom", "Tel", "Email", "Nom du Vendeur", "Id du Vendeur", "Shop", "Offre"];
  //     const csvContent = headers.join(",") + "\n";
  //
  //     const rows = filteredCustomers.map(customer => {
  //         return [customer.metadata.id, timestampToDate(customer.created), customer.metadata.firstName, customer.metadata.lastName, customer.metadata.phone, customer.metadata.email, customer.metadata.vendorName, customer.metadata.vendorId, customer.metadata.shop, customer.metadata.plan === process.env.REACT_APP_STRIPE_SILVER_OFFER ? "Basique" : "Premium"].map(value => JSON.stringify(value)).join(",");
  //     });
  //
  //     return csvContent + rows.join("\n");
  // }

  // function downloadCSV(csvContent, fileName) {
  //     const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement("a");
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", fileName);
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  // }

  // const handleExportCSV = () => {
  //     const csvData = generateCSVData(filteredCustomers);
  //     downloadCSV(csvData, `Souscriptions_Coyali_de_${startDate}_a_${endDate}.csv`);
  // };
  // const handleRangeChange = (start, end) => {
  //     setStartDate(start);
  //     setEndDate(end);
  // };


  // Call the getCustomClaimRole function
  // getCustomClaimRole();
  return (<main className="userList">
    <h1>Liste des souscriptions</h1>
    <div className="sorter_container">
      {/*<button className="primary-btn btn export-btn" onClick={handleExportCSV}>Exporter en Excel</button>*/}

      {/*<div className="range-picker">*/}
      {/*    <p className="range-txt">Choisissez une plage de dates :</p>*/}
      {/*    <input*/}
      {/*        type="date"*/}
      {/*        value={startDate || ''}*/}
      {/*        onChange={(e) => setStartDate(e.target.value)}*/}
      {/*    />*/}
      {/*    <input*/}
      {/*        type="date"*/}
      {/*        value={endDate || ''}*/}
      {/*        onChange={(e) => setEndDate(e.target.value)}*/}
      {/*    />*/}

      {/*    <button className="btn primary-btn" onClick={() => console.log("test")}>*/}
      {/*        Chercher*/}
      {/*    </button>*/}

      {/*    <button className="btn secondary-btn" onClick={() => handleRangeChange(null, null)}>*/}
      {/*        Réinitialiser*/}
      {/*    </button>*/}
      {/*</div>*/}
    </div>
    {/*<div className="search-bar">*/}
    {/*    <p className="search-title">Filtrer les résultats</p>*/}
    {/*    <input*/}
    {/*        type="text"*/}
    {/*        placeholder="Rechercher..."*/}
    {/*        value={searchTerm}*/}
    {/*        onChange={e => setSearchTerm(e.target.value)}*/}
    {/*    />*/}
    {/*</div>*/}
    <div className="user-list_container">
      {loadingData ? (<div>Chargement...</div>) : (
          <NestedSubscriptionList subscriptions={displayedSubscriptions}/>)}
    </div>
  </main>);
}

export default SubscriptionList;