import * as React from 'react';
import {SubscriptionDto} from "../../models/StripeDto";
import {DataGrid, GridColDef, GridRowsProp} from "@mui/x-data-grid";

const columns: GridColDef[] = [
  // { field: 'id', headerName: 'ID', width: 290 },
  // { field: 'lastName', headerName: 'Prénom', width: 120, editable: false },
  {field: 'name', headerName: 'Client', width: 170, editable: false},
  {field: 'date', headerName: 'Date', width: 120, editable: false},
  {field: 'phone', headerName: 'Tel', type: 'number', width: 140, editable: false},
  {field: 'email', headerName: 'Email', type: 'string', width: 230, editable: true},
  // { field: 'Id_vendeur', headerName: 'Id du vendeur', sortable: false, width: 130 },
  {field: 'vendor_name', headerName: 'Vendeur', width: 130},
  {field: 'shop', headerName: 'Boutique', width: 130},
  {field: 'offre', headerName: 'Offre', sortable: false, width: 150},
  {field: 'status', headerName: 'Statut', width: 130},
];

interface Prop {
  subscriptions: SubscriptionDto[]
}

export default function NestedSubscriptionList({subscriptions}: Prop) {
  function timestampToDate(timestamp: number) {
    const date = new Date(timestamp * 1000);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function friendlyStatus(status: string) {
    switch (status) {
      case "active":
        return "Actif"
      case "past_due":
        return "Retard de paiement"
      case "unpaid":
        return "Impayé"
      case "canceled":
        return "Annulé"
      case "incomplete":
        return "Incomplet"
      case "incomplete_expired":
        return "Expiré"
      case "trialing":
        return "Essai gratuit"
      case "paused":
        return "En pause"
      default:
        return "Inconnu"
    }
  }

  const rows: GridRowsProp = subscriptions.map((subscription) => (
      {
        id: subscription.id,
        date: timestampToDate(subscription?.created),
        name: subscription?.customer.name,
        // lastName: subscription?.metadata?.firstName,
        phone: subscription?.metadata?.phone,
        // phone: subscription?.customer?.phone,
        email: subscription?.customer?.email,
        vendor_name: subscription?.metadata?.vendorName,
        // Id_vendeur: subscription?.metadata?.vendorId,
        shop: subscription?.metadata?.shop,
        offre: subscription?.items?.data[0]?.price?.nickname || "",
        status: friendlyStatus(subscription?.status)
      })).filter(row => row.id);  // Filtrer les rows ayant un id valide

  return (
      <div style={{height: 600, width: '100%'}}>
        <DataGrid
            rows={rows}
            columns={columns}
            checkboxSelection
            pagination={undefined}
            // disableColumnResize={false}
            // disableSelectionOnClick
            // onPageChange={(newPage: React.MouseEvent) => setActualPage(newPage)} // la pagination commence à 0
        />
        {/*rowsPerPageOptions={[10, 20, 50]}*/}
        {/*disableColumnResize={false}*/}

      </div>
  );
}
