
import React from "react";


function Validation () {

    return (
        <div className="validation">
            <h2>
                La souscription à bien été prise en compte. 
            </h2>
            <button className="primary-btn validation-btn">
                Nouvelle Souscription
            </button>
        </div>
    )
}

export default Validation