import React from "react";
import "../sass/pages/NotFound.scss"


function NotFound () {
    return (
        <div className="notFound">
            <h1>404 <br/>
            Not Found</h1>
        </div>
    )
}

export default NotFound