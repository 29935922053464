import React from "react";
import LoginForm from "./LoginForm";


function LoginContent () {
    return (
        <section className="sign-in-content">
                <div className="hotline">Hotline dédiée : +33 9 78 45 07 94</div>
                <h1>Connexion</h1>
                <LoginForm/>
        </section>
    )
}
export default LoginContent