import React, {useEffect, useRef, useState} from "react";
import "../../sass/components/StepSubscribe.scss";
import {createSignatureRequestCF} from '../../cloudFunctions/subscription/createSignatureRequestCF';
import {firestore} from "../../firebase";
import {doc, onSnapshot} from 'firebase/firestore';
import {Subscription} from "../../models/Subscription";
import {isProd} from "../../utils/isProd";
import {Button, Grid} from "@mui/material";
import arrow from "../../assets/icons/arrow.svg";
import firebase from "firebase/compat";


interface Prop {
  nextStep: () => void,
  subscription: Subscription
}

function Signature({nextStep, subscription}: Prop) {
  //  Ce n'est pas parce qu'on a l'url de la signature que l'iframe peut être affichée de suite
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [signatureReady, setSignatureReady] = useState(false);
  const [docListener, setDocListener] = useState(false);


  function replacePlus33With00(phoneNumber: string) {
    return phoneNumber.replace("+33", "0033");
  }

  const docRef = doc(firestore, `partners/${subscription.siren}/subscriptions`, replacePlus33With00(subscription.customer.phone));

  // let unsub: any;
  let unsub: React.MutableRefObject<undefined | firebase.Unsubscribe> = useRef();

  useEffect(() => {
    if (!docListener) return;
    unsub.current = onSnapshot(docRef, (doc) => {
      if (!doc.exists()) {
        console.log('Document doesn\'t exist!');
      } else if (doc.data().status === "signature_ready") {
        console.log("signature_ready, url : ", iframeUrl)
        setSignatureReady(true);
      } else if (doc.data().status === "signed") {
        next()
      } else {
        console.log('Document found but, but not signed!');
      }
    });
  }, [docListener]);


  function next() {
    if (unsub.current) {
      unsub.current();
    }
    nextStep()
  }

  useEffect(() => {
    createSignatureRequestCF(subscription, (url: string) => {
      console.log(url)
      setDocListener(true)
      setIframeUrl(url);
    });
  }, []);


  useEffect(() => {
    if (signatureReady && iframeUrl) {
      console.log("iframe : ", iframeUrl)

      const yousign = new Yousign({
        signatureLink: iframeUrl,
        iframeContainerId: 'iframe-container',
        isSandbox: !isProd(),
      });

      yousign.onSuccess((data: any) => {
        console.log("Signer has successfully signed", data);
      });

      yousign.onError((data: any) => {
        console.log("Signer encountered an error when signing");
        console.log(data);
      });

      yousign.onStarted((data: any) => {
        console.log("Signer has opened the signature");
        console.log(data);
      });

      if (!isProd()) {
        yousign.onPing((data: any) => {
          console.log("Ping - The signature request is loaded");
          console.log(data);
        });
      }
    }
  }, [signatureReady, iframeUrl])


  if (signatureReady && iframeUrl) {
    return (
        <div className="step">
          <h2 className="step-title">
            <span>Étape 3</span>: Prendre connaissance et Accepter les CGV.
          </h2>

          <Grid container spacing={3}>
            <Grid item xs={12} container justifyContent="flex-end">
              <div className="user-infos_form">
                <div id="iframe-container"
                     className="yousign-iframe"></div>
              </div>
            </Grid>


            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                  variant="contained"
                  onClick={next}
                  endIcon={<img className="arrow" src={arrow} alt=""/>}>
                Signature terminée
              </Button>
            </Grid>
          </Grid>
        </div>
    );
  } else {
    return (
        <div className="step">
          Chargement ...<br/>
          Cette étape peut prendre quelques instants
        </div>
    );
  }
}

export default Signature;
