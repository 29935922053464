import {getFunctions, httpsCallable, HttpsCallableResult} from "firebase/functions";
import {SetClaimsDto} from "../../models/SetClaimsDto";

export function setClaimsCF(claimsDto: SetClaimsDto): Promise<any> {
  return new Promise((resolve, reject) => {
    const setClaims = httpsCallable(getFunctions(), 'V2_setClaims');

    setClaims(JSON.stringify(claimsDto)).then((result: HttpsCallableResult<any>) => {
      console.log("options : ", JSON.parse(result.data));
      resolve(result);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}