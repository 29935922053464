import React, {useEffect, useState} from "react";
import "../../sass/components/StepSubscribe.scss"
import {stripePromise} from "../../App";
import {Elements} from "@stripe/react-stripe-js";
import {ProgressBar} from "react-loader-spinner";
import StripeForm from "./StripeForm";
import {Subscription} from "../../models/Subscription";
import {createStripeSubscriptionCF} from "../../cloudFunctions/subscription/createStripeSubscriptionCF";

interface Prop {
  nextStep: () => void;
  subscription: Subscription;
}


function Payment({nextStep, subscription}: Prop) {

  const [options, setOptions] = useState<any>(undefined);

  useEffect(() => {
    createStripeSubscriptionCF(
        subscription
    ).then((result) => {
      console.log("test stripe : ", result)
      setOptions(result)
    })
  }, []);

  return (<div className="step">
        <h2 className="step-title"><span>Étape 4 </span>: Procéder au paiement sécurisé. </h2>

        {options
            ? <Elements stripe={stripePromise} options={options}>
              <StripeForm nextStep={nextStep} subscription={subscription}/>
            </Elements>
            : <div>
              <p>Vous allez être redirigé vers notre système de paiement sécurisé</p>
              <ProgressBar
                  height="80"
                  width="80"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  borderColor='#283583'
                  barColor='#DC6413'
              />
            </div>
        }
      </div>
  )
}

export default Payment