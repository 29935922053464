import React from "react";
import LoginContent from "../components/login/LoginContent";
import logo from '../assets/images/logoCoyali.svg'
import "../sass/pages/Login.scss"


function Login() {
  return (
      <main className="login">
        <div className="login-content">
          <div>
            <img src={logo} alt="Coyali"/>
          </div>
          <LoginContent/>
        </div>
      </main>
  )
}

export default Login