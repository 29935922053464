import {
  browserLocalPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword
} from "firebase/auth";
import {useDispatch} from "react-redux";
import {startSession} from "../../redux/features/user";
import {useNavigate} from "react-router-dom";
import {auth, firestore} from "../../firebase";
import {doc, getDoc} from "firebase/firestore";
import GenericForm from "../generic/GenericForm";
import GenericInput from "../generic/GenericInput";
import hide from "../../assets/icons/hide.png";
import view from "../../assets/icons/view.png";
import {SubscribePath} from "../../pages/Subscribe";
import * as React from "react";
import {useEffect, useState} from "react";
import {User} from "../../models/User";
import {firestoreObjectToMap} from "../../utils/FirestoreObjectToMap";

function LoginForm() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Utilisateur déjà authentifié
        console.log("Utilisateur connecté :", user);
        // Effectuez les actions nécessaires avec les informations de l'utilisateur connecté
        const claims = (await user.getIdTokenResult(true)).claims;
        console.log("claims : ", claims)
        // console.log("seller : ", claims.seller?[0] ? claims.seller[0] : "null")
        const siren = claims.seller[0].toString()
        const userRef = doc(firestore, "partners", siren);
        const userSnapshot = await getDoc(userRef);

        console.log("claims : ", claims)
        console.log("siren : ", siren)
        console.log("juste avant userSnapshot.exists")

        // FIXME ajouter les claims
        if (userSnapshot.exists()) {
          const customUser: User = {
            name: user.displayName ? user.displayName : "",
            claims: {
              admin: claims.admin ? claims.admin : false,
              seller: claims.seller ? claims.seller.map(String) : [],
              manager: claims.manager ? claims.manager.map(String) : [],
              stripeRole: claims.stripeRole ? claims.stripeRole : ""
            },
            shops: firestoreObjectToMap(userSnapshot.data().stores),
            sellers: firestoreObjectToMap(userSnapshot.data().sellers)
          }
          console.log("juste avant startSession")

          dispatch(startSession(customUser));

          console.log("juste avant navigate")
          navigate(SubscribePath);
        } else {
          console.log("Document partner introuvable");
        }
      }
    });

    setPersistence(auth, browserSessionPersistence)
        .then(() => {
          console.log("Persistance de session activée");
        })
        .catch((error) => {
          console.log("Erreur de configuration de la persistance :", error);
        });


    return () => {
      unsubscribe();
    };
  }, []);


  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!password?.length || !userName?.length) return;

    setPersistence(auth, browserLocalPersistence)
        .then(() => {
          signInWithEmailAndPassword(auth, userName, password)
              .then(async (userCredential) => {
                console.log(userCredential)
              })
              .catch((error) => {
                // Gérer les erreurs de la méthode signInWithEmailAndPassword
                setErrorLogin(true)
                console.log("Erreur de connexion:", error);
              });
        })
        .catch((error) => {
          console.log("Erreur de configuration de la persistance:", error);
        });
  };

  const handleChangeUsername = (e: React.FormEvent<HTMLInputElement>) => {
    setErrorLogin(false);
    setUserName(e.currentTarget.value);
  };


  const handleChangePassword = (e: React.FormEvent<HTMLInputElement>): void => {
    setErrorLogin(false);
    setPassword(e.currentTarget.value);
  };
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
      <GenericForm submitFunction={handleSubmit} action={undefined} className={undefined}>

        <GenericInput
            // className="input-wrapper"
            placeholder="Entrez votre email"
            label="Email"
            htmlFor="email"
            id="email"
            type="email"
            handleChangeFunction={handleChangeUsername}
            valueInput={undefined}/>

        <div className="input-wrapper password">
          <label htmlFor="password">Mot de passe</label>
          <input
              type={showPassword ? "text" : "password"}
              id="password"
              placeholder="Entrez votre mot de passe"
              onChange={handleChangePassword}
          />
          <button className="show-password-btn" type="button" onClick={toggleShowPassword}>
            {showPassword ? <img src={view} alt="Hide"/> : <img src={hide} alt="Show"/>}
          </button>
        </div>

        {errorLogin && (
            <div className="error-message">Vos identifiants sont incorrects. Veuillez réessayer !</div>
        )}

        <div className="form-cmd">
          <input id="submit-button" className="primary-btn" type="submit" value="Se connecter"/>
          <a href="#">Mot de passe oublié.</a>
        </div>
      </GenericForm>
  );
}

export default LoginForm;
