import {configureStore} from '@reduxjs/toolkit'
import userState from "./features/user"


const store = configureStore({
  reducer: {
    userState: userState,
  }
})


export type RootState = ReturnType<typeof store.getState>

export default store