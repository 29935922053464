import {getFunctions, httpsCallable, HttpsCallableResult} from "firebase/functions";
import {StripeElementsOptions} from "@stripe/stripe-js";
import {Subscription} from "../../models/Subscription";


export function createStripeSubscriptionCF(subscription: Subscription): Promise<StripeElementsOptions | undefined> {
  return new Promise((resolve, reject) => {
    const createSubscription = httpsCallable(getFunctions(), 'V2_createStripeSubscription');

    createSubscription(subscription).then((result: HttpsCallableResult<any>) => {
      console.log("test stripe : ", JSON.stringify(result))
      console.log("options : ", result);
      resolve({clientSecret: result.data.clientSecret});
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}