import {getFunctions, httpsCallable, HttpsCallableResult} from "firebase/functions";
import {AdminUpdateUserInfoDto} from "../../models/AdminUpdateUserInfoDto";

export function updateUserInfoCF(dto: AdminUpdateUserInfoDto): Promise<any> {
  return new Promise((resolve, reject) => {
    const setClaims = httpsCallable(getFunctions(), 'V2_adminUpdateUserInfo');

    setClaims(JSON.stringify(dto)).then((result: HttpsCallableResult<any>) => {
      console.log("options : ", JSON.parse(result.data));
      resolve(result);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}