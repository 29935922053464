import React, {useState} from "react";
import "../sass/pages/Subscribe.scss";
import addUser from "../assets/icons/add-user.svg";
import GetUserInfos from "../components/Subscribe/GetUserInfo";
import Signature from "../components/Subscribe/Signature";
import Payment from "../components/Subscribe/Payment";
import ConfirmData from "../components/Subscribe/ConfirmData";
import ProgressBar from "../components/Subscribe/ProgressBar";
import {Subscription} from "../models/Subscription";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {User} from "../models/User";
import Validation from "../components/Subscribe/Validation";

export const SubscribePath = "/subscribe"

// TODO renommer en NewSubscription
function Subscribe() {
  const [step, setStep] = useState(1);
  const user = useSelector((state: RootState) => (state.userState.user as User));

  const [subscription, setSubscription] = useState<Subscription>(
      {
        customer: {
          gender: "",
          firstName: "",
          lastName: "",
          address: {
            country: "",
            postalCode: "",
            state: "",
            city: "France",
            line1: "",
            line2: "",
          },
          phone: "",
          email: "",
          birthdate: ""
        },
        price: process.env.REACT_APP_STRIPE_GOLD_OFFER ? process.env.REACT_APP_STRIPE_GOLD_OFFER : "",
        timestamp: Date.now().toString(),
        siren: user.claims.seller?.[0] as string,
        shop: {
          id: "",
          name: ""
        },
        seller: {
          id: "",
          name: ""
        },
      });

  function nextStep() {
    setStep(step + 1);
    console.log(subscription)
  }

  function rollback() {
    setStep(step - 1)
  }

  console.log(subscription)
  return (
      <main className="subscribe">
        <ProgressBar step={step}/>
        <div className="subscribe-content">
          <h1>
            <img src={addUser} alt=""/> Souscriptions
          </h1>

          {step === 1 ? <GetUserInfos
              nextStep={nextStep}
              subscription={subscription}
              setSubscription={setSubscription}/> : null}

          {step === 2 ? <ConfirmData nextStep={nextStep} subscription={subscription} rollback={rollback}/> : null}

          {step === 3 ? <Signature nextStep={nextStep} subscription={subscription}/> : null}

          {step === 4 ? <Payment nextStep={nextStep} subscription={subscription}/> : null}

          {step === 5 ? <Validation/> : null}

        </div>
      </main>
  );
}

export default Subscribe;