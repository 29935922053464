import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {User} from "../../models/User";

interface UserState {
    user?: User
}

const initialState: UserState = {
    user: undefined
}

export const userSlice = createSlice({
    name: "userState",
    initialState: initialState,
    reducers : {
        startSession: (state, action: PayloadAction<User>) => {
            state.user = action.payload
        },
        stopSession : (state) => {
            state.user = undefined
        },
    }
})
export const {startSession, stopSession } = userSlice.actions
export default userSlice.reducer