import React from "react";
import {Link, useNavigate} from "react-router-dom"
import logo from "../../assets/images/logoCoyali.svg"
import "../../sass/components/Header.scss"
import {useDispatch, useSelector} from "react-redux"
import addUser from "../../assets/icons/add-user.svg"
import userList from "../../assets/icons/list.svg"
import {signOut} from "firebase/auth";
import {auth} from "../../firebase"
import {SubscriptionsListPath} from "../../pages/subscriptions/SubscriptionList";
import {AdminPagePath} from "../../pages/AdminPage";
import {stopSession} from "../../redux/features/user";
import {SubscribePath} from "../../pages/Subscribe";
import {RootState} from "../../redux/store";
import {User} from "../../models/User";

function Header() {
  const user = useSelector((state: RootState) => (state.userState.user as User));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleLogout(e: React.MouseEvent) {
    e.preventDefault()
    signOut(auth)
        .then(() => {
          // Déconnexion réussie
          dispatch(stopSession());
          // dispatch(cleanUserProfile());
          navigate('/')
          console.log("Déconnexion réussie");
        })
        .catch((error) => {
          // Une erreur s'est produite lors de la déconnexion
          console.log("Erreur lors de la déconnexion :", error);
        });
  }


  

  return (
      <header className="app-header">
        <div className="header-content">
          <div className="navigation">
            <Link to="/" className="logo">
              <img src={logo} alt="retourner à l'accueil"/>
            </Link>


            <Link className="nav-item" to={SubscribePath} ><img src={addUser}
                                                                                           alt=""/> Souscrire</Link>


            <Link to={SubscriptionsListPath} className="nav-item"><img src={userList} alt=""/> Liste des
              souscriptions</Link>


            {user.claims.admin ? <Link to={AdminPagePath} className="nav-item">Admin</Link> : null}


          </div>
          <div className="logout">
            <p className="secondary-btn">Hotline dédiée : +33 9 78 45 07 94</p>
            <button className="primary-btn" onClick={(e) => handleLogout(e)}>Déconnexion</button>
          </div>

        </div>
      </header>
  )
}

export default Header

/* <Link to="/Dashboard" className="nav-item"><img src={graph} alt="" />Tableau de bord</Link>*/