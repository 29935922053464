import React, {useState} from "react";
import arrow from "../../assets/icons/arrow.svg";
import {priceToFriendlyName} from "../../utils/PriceToFriendlyName";
import {Subscription} from "../../models/Subscription";

interface Prop {
  nextStep: () => void,
  rollback: () => void,
  subscription: Subscription,
}

function ConfirmData({nextStep, rollback, subscription}: Prop) {

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const next = (e: React.MouseEvent) => {
    e.preventDefault()
    nextStep()
  };


  return (
      <div className="step">
        <h2 className="step-title">
          <span>Étape 2</span>: Vérification des données de l'utilisateur.
        </h2>
        <div className="user-infos_form">
          <div className="user-data">
            <div className="item-infos">
              <p>Nom du vendeur: </p>
              <p>{subscription ? subscription?.seller.name : ""}</p>
            </div>
            <div className="item-infos">
              <p>Boutique : </p>
              <p>{subscription ? subscription?.shop.name : ""}</p>
            </div>
            <div className="item-infos">
              <p>Nom du client : </p>
              <p>{subscription ? subscription?.customer.firstName : ""}</p>
            </div>
            <div className="item-infos">
              <p>Prénom du client : </p>
              <p>{subscription ? subscription?.customer.lastName : ""}</p>
            </div>
            <div className="item-infos">
              <p>Genre du client : </p>
              <p>{subscription ? subscription?.customer.gender : ""}</p>
            </div>
            <div className="item-infos">
              <p>Date de naissance : </p>
              <p>{subscription ? subscription?.customer.birthdate : ""}</p>
            </div>
            <div className="item-infos">
              <p>Email du client : </p>
              <p>{subscription ? subscription?.customer.email : ""}</p>
            </div>
            <div className="item-infos">
              <p>Numéro de Téléphone du client : </p>
              <p>{subscription ? subscription?.customer.phone : ""}</p>
            </div>
            <div className="item-infos">
              <p>Formule choisie : </p>
              <p>{priceToFriendlyName(subscription?.price ? subscription?.price : "")}</p>
            </div>


            <button className="primary-btn sign-btn red" onClick={rollback}>
              Je dois corriger les informations
            </button>
          </div>
          <div className="separator">

          </div>
          <div className="validation-group">
            <div className="checkbox-wrapper">
              <input
                  type="checkbox"
                  id="certifyCheckbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
              />
              <label htmlFor="certifyCheckbox">Je certifie l'exactitude des informations</label>
            </div>
            <button className="primary-btn sign-btn " onClick={next}
                    disabled={!isChecked}>
              Etape suivante
              <img src={arrow} alt="paiement sur stripe"/>
            </button>
          </div>
        </div>
      </div>
  );
}

export default ConfirmData;