import React from "react";
import "../../sass/components/StepSubscribe.scss"
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Subscription} from "../../models/Subscription";
import {Button, Container, Grid} from "@mui/material";


interface Prop {
  subscription?: Subscription | undefined,
  nextStep: () => void
}

function StripeForm({nextStep, subscription}: Prop) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: React.MouseEvent) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href
      },
      redirect: "if_required"
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      nextStep();
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };


  return (
      // FIXME afficher un message d'erreur
      <Container>
        <form>

          <PaymentElement options={
            {
              paymentMethodOrder: ['sepa_debit', 'card'],
              defaultValues: {
                billingDetails: {
                  name: subscription?.customer?.firstName + " " + subscription?.customer?.lastName,
                  email: subscription?.customer?.email,
                  phone: subscription?.customer?.phone,
                  address: {
                    country: subscription?.customer?.address?.country,
                    postal_code: subscription?.customer?.address?.postalCode,
                    state: subscription?.customer?.address?.state,
                    city: subscription?.customer?.address?.city,
                    line1: subscription?.customer?.address?.line1,
                    line2: subscription?.customer?.address?.line2,
                  }
                }
              }
            }
          }/>

          <Grid item xs={12}>
            <Button fullWidth variant="contained" type="submit" onClick={handleSubmit}>Soumettre</Button>
          </Grid>
        </form>
      </Container>

  )
}

export default StripeForm