import React from 'react';
import '../../sass/components/ProgressBar.scss'; // Import du fichier SASS

interface Prop {
  step: number
}
function ProgressBar({step}: Prop) {

  return (
    <div className="progress-bar">
      <div className={`circle ${step === 1 ? 'active' : step > 1 ? 'passed' : ''}`}>1 <span className='title-user-info one'>Renseigner</span></div>
      <div className={`line ${step > 1 ? 'active' : ''}`}></div>
      <div className={`circle ${step === 2 ? 'active' : step > 2 ? 'passed' : ''}`}>2 <span className='title-user-info'>Vérifier</span></div>
      <div className={`line ${step > 2 ? 'active' : ''}`}></div>
      <div className={`circle ${step === 3 ? 'active' : step > 3 ? 'passed' : ''}`}>3 <span className='title-user-info'>Signer</span></div>
      <div className={`line ${step > 3 ? 'active' : ''}`}></div>
      <div className={`circle ${step === 4 ? 'active' : step > 4 ? 'passed' : ''}`}>4 <span className='title-user-info'>Payer</span></div>
      <div className={`line ${step > 4 ? 'active' : ''}`}></div>
      <div className={`circle ${step === 5 ? 'active' : step > 5 ? 'passed' : ''}`}>5 <span className='title-user-info'>Valider</span></div>
    </div>
  );
}

export default ProgressBar;
