import React, {HTMLInputTypeAttribute} from "react";

interface Prop {
  htmlFor?: string | undefined,
  type?: HTMLInputTypeAttribute | undefined,
  id: string,
  label: string,
  placeholder: string,
  valueInput?: string | ReadonlyArray<string> | number | undefined,
  handleChangeFunction: React.ChangeEventHandler<HTMLInputElement>
}

function GenericInput({htmlFor, type, id, label, placeholder, valueInput, handleChangeFunction}: Prop) {
  return (
      <div className="input-wrapper">
        <label htmlFor={htmlFor}>{label}</label>
        <input type={type} id={id} placeholder={placeholder} value={valueInput} onChange={handleChangeFunction}/>
      </div>
  )
}

export default GenericInput